import React from 'react'

import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import configureStore from './configureStore'

import { addLocaleData, IntlProvider } from 'react-intl'
import en from 'react-intl/locale-data/en'
import fr from 'react-intl/locale-data/fr'

// const store = initStore()
const { persistor, store } = configureStore()

// Configure i18n
addLocaleData([...en, ...fr])

const wrapRootProvider = ({ element }) => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <IntlProvider
        // locale={navigator.language}
        locale='fr'
        defaultLocale='fr'
        // messages={translationsForUsersLocale}
      >
        {element}
      </IntlProvider>
    </PersistGate>
  </Provider>
)

export default wrapRootProvider
