module.exports = [{
      plugin: require('/codebuild/output/src529513981/src/git-codecommit.eu-west-1.amazonaws.com/v1/repos/www.phileass.com/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-111465699-1","head":true,"anonymize":false,"respectDNT":true},
    },{
      plugin: require('/codebuild/output/src529513981/src/git-codecommit.eu-west-1.amazonaws.com/v1/repos/www.phileass.com/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/codebuild/output/src529513981/src/git-codecommit.eu-west-1.amazonaws.com/v1/repos/www.phileass.com/node_modules/@wapps/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/codebuild/output/src529513981/src/git-codecommit.eu-west-1.amazonaws.com/v1/repos/www.phileass.com/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
