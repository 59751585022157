
// ACTIONS

export const CREATE_CONTACTREQUEST = 'CREATE_CONTACTREQUEST'
export const START_QUOTEREQUEST = 'START_QUOTEREQUEST'
export const UPDATE_QUOTEREQUEST = 'UPDATE_QUOTEREQUEST'
export const QUOTE_QUOTEREQUEST = 'QUOTE_QUOTEREQUEST'
export const SUBSCRIBE_QUOTE = 'SUBSCRIBE_QUOTE'
export const UPDATE_SUBSCRIBEREQUEST = 'UPDATE_SUBSCRIBEREQUEST'

// ACTION_METHODS

export function createContactRequest (contactRequest) {
  return {
    type: CREATE_CONTACTREQUEST,
    contactRequest: contactRequest
  }
}

export function startQuoteRequest (quoteRequest) {
  return {
    type: START_QUOTEREQUEST,
    quoteRequest: quoteRequest
  }
}

export function updateQuoteRequest (quoteRequest) {
  return {
    type: UPDATE_QUOTEREQUEST,
    quoteRequest: quoteRequest
  }
}

export function quoteQuoteRequest (quote) {
  return {
    type: QUOTE_QUOTEREQUEST,
    quote: quote
  }
}

export function subscribeQuote (subscribeRequest) {
  return {
    type: SUBSCRIBE_QUOTE,
    subscribeRequest: subscribeRequest
  }
}

export function updateSubscribeRequest (subscribeRequest) {
  return {
    type: UPDATE_SUBSCRIBEREQUEST,
    subscribeRequest: subscribeRequest
  }
}
