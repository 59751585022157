import { createStore, combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import { contactReducer, quoteReducer } from './reducers'

// Init Root Reducer
const rootReducer = combineReducers({
  // ContactRequest actions
  contact: contactReducer,
  // QuoteRequest actions
  quote: quoteReducer,
  // ReduxForm actions
  form: formReducer
})

// Global config for persistence (local storage config)
const persistConfig = {
  key: 'root',
  storage: storage,
  stateReconciler: autoMergeLevel2,
  // Never store the captcha token
  blacklist: ['captchaToken']
}
const persistedReducer = persistReducer(persistConfig, rootReducer)

const configureStore = () => {
  // createStore(rootReducer)
  const store = createStore(
    persistedReducer,
    // Redux DevTools extension
    ((typeof window !== 'undefined') && (window.__REDUX_DEVTOOLS_EXTENSION__) && window.__REDUX_DEVTOOLS_EXTENSION__())
  )
  const persistor = persistStore(store)

  return {
    store,
    persistor
  }
}

export default configureStore
