// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-page-template-js": () => import("/codebuild/output/src529513981/src/git-codecommit.eu-west-1.amazonaws.com/v1/repos/www.phileass.com/src/templates/pageTemplate.js" /* webpackChunkName: "component---src-templates-page-template-js" */),
  "component---src-templates-section-index-template-js": () => import("/codebuild/output/src529513981/src/git-codecommit.eu-west-1.amazonaws.com/v1/repos/www.phileass.com/src/templates/sectionIndexTemplate.js" /* webpackChunkName: "component---src-templates-section-index-template-js" */),
  "component---src-pages-404-js": () => import("/codebuild/output/src529513981/src/git-codecommit.eu-west-1.amazonaws.com/v1/repos/www.phileass.com/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("/codebuild/output/src529513981/src/git-codecommit.eu-west-1.amazonaws.com/v1/repos/www.phileass.com/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-devis-old-js": () => import("/codebuild/output/src529513981/src/git-codecommit.eu-west-1.amazonaws.com/v1/repos/www.phileass.com/src/pages/devis_old.js" /* webpackChunkName: "component---src-pages-devis-old-js" */),
  "component---src-pages-devis-index-js": () => import("/codebuild/output/src529513981/src/git-codecommit.eu-west-1.amazonaws.com/v1/repos/www.phileass.com/src/pages/devis/index.js" /* webpackChunkName: "component---src-pages-devis-index-js" */),
  "component---src-pages-index-js": () => import("/codebuild/output/src529513981/src/git-codecommit.eu-west-1.amazonaws.com/v1/repos/www.phileass.com/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-plan-du-site-js": () => import("/codebuild/output/src529513981/src/git-codecommit.eu-west-1.amazonaws.com/v1/repos/www.phileass.com/src/pages/plan-du-site.js" /* webpackChunkName: "component---src-pages-plan-du-site-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/codebuild/output/src529513981/src/git-codecommit.eu-west-1.amazonaws.com/v1/repos/www.phileass.com/.cache/data.json")

