
import {
  CREATE_CONTACTREQUEST,
  START_QUOTEREQUEST,
  UPDATE_QUOTEREQUEST,
  QUOTE_QUOTEREQUEST,
  SUBSCRIBE_QUOTE,
  UPDATE_SUBSCRIBEREQUEST
} from './actions'

// Contact

export function contactReducer (state = {}, action) {
  switch (action.type) {
    case CREATE_CONTACTREQUEST:
      return {
        ...state,
        contactRequest: action.contactRequest
      }
    default:
      return state
  }
}

// Quote

export const QUOTE_STATE_PENDING = 'PENDING'
export const QUOTE_STATE_QUOTED = 'QUOTED'

export function quoteReducer (state = {}, action) {
  switch (action.type) {
    case START_QUOTEREQUEST:
      return {
        ...state,
        state: QUOTE_STATE_PENDING,
        quoteRequest: action.quoteRequest
        // quote: undefined
      }
    case UPDATE_QUOTEREQUEST:
      console.log('Reducer: received UPDATE_QUOTEREQUEST with quoteRequest: ', action.quoteRequest)
      return {
        ...state,
        state: QUOTE_STATE_PENDING,
        quoteRequest: action.quoteRequest
        // quote: undefined
      }
    case QUOTE_QUOTEREQUEST:
      console.log('Reducer: received QUOTE_QUOTEREQUEST with quote: ', action.quote)
      return {
        ...state,
        state: QUOTE_STATE_QUOTED,
        quote: action.quote
      }
    case SUBSCRIBE_QUOTE:
      console.log('Reducer: received SUBSCRIBE_QUOTE with subscribeRequest: ', action.subscribeRequest)
      return {
        ...state,
        subscribeRequest: action.subscribeRequest
      }
    case UPDATE_SUBSCRIBEREQUEST:
      console.log('Reducer: received UPDATE_SUBSCRIBEREQUEST with subscribeRequest: ', action.subscribeRequest)
      return {
        ...state,
        subscribeRequest: action.subscribeRequest
      }
    default:
      return state
  }
}
